import React from 'react'
import Link from 'next/link'
import { Flex } from 'components/primitives'
import NavItem from 'components/navbar/NavItem'
import PopChainDropdown from 'components/custom/PopChainDropdown'
import { nfts } from 'config/routes'

const NftNavItems = () => {
  return (
    <Flex
      align="center"
      css={{
        gap: '$5',
        ml: '$5',
      }}
    >
      {Object.keys(nfts).map((systemId) =>
        Object.keys(nfts[systemId].chains).length > 1 ? (
          <PopChainDropdown key={systemId} systemId={systemId} />
        ) : (
          <Link
            key={systemId}
            href={`/nft/${systemId}?chain=${
              Object.keys(nfts[systemId].chains)[0]
            }`}
          >
            <NavItem
              analyticsId={`click marketplace ${nfts[
                systemId
              ].name.toLowerCase()}`}
              chain={Object.keys(nfts[systemId].chains)[0]}
              systemId={systemId}
            >
              {nfts[systemId].name}
            </NavItem>
          </Link>
        )
      )}
    </Flex>
  )
}

export default NftNavItems
