import { default as NextLink, LinkProps as NextLinkProps } from 'next/link'

import { withClickAnalytics } from 'components/wrappers/withClickAnalytics'

export type LinkProps = NextLinkProps & {
  children: React.ReactNode
  className?: string
  target?: string
  rel?: string
}

const Link = ({ className, ...props }: LinkProps) => <NextLink {...props} />

export default withClickAnalytics(Link)
