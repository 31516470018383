import { useAuthLogin } from 'hooks/auth/useAuthLogin'
import Button from 'components/primitives/Button'
import { FC } from 'react'

type Props = {}

export const ConnectWalletButton: FC<Props> = () => {
  const { login } = useAuthLogin()
  return (
    <Button
      css={{ flex: 1, justifyContent: 'center' }}
      corners="rounded"
      onClick={() => login()}
      type="button"
    >
      Connect Wallet
    </Button>
  )
}
