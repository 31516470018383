import { styled } from '@stitches/react'
import Image from 'next/image'
import {
  DropdownMenuItem,
  DropdownMenuContent,
} from 'components/primitives/Dropdown'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import NavItem from 'components/navbar/NavItem'
import { chains, nfts } from 'config/routes'
import Link from 'next/link'
import { Button } from 'components/primitives'

const ChevronDownIcon = styled(Image, {
  transition: 'transform 0.2s ease-in-out',
  '[data-state="open"] &': {
    transform: 'rotate(180deg)',
  },
})

const PopChainDropdown = ({ systemId }: { systemId: string }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div>
          <Button color="nav">
            {nfts[systemId].name}
            <ChevronDownIcon
              src="/icons/angle-down.svg"
              alt="accordion arrow"
              width={16}
              height={16}
              aria-hidden
            />
          </Button>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenuContent css={{ width: '160px' }}>
        {Object.keys(nfts[systemId].chains).map((chainId) => (
          <DropdownMenuItem key={`menu-${systemId}-${chainId}`}>
            <Link href={`/nft/${systemId}?chain=${chainId}`}>
              <NavItem
                analyticsId={`click marketplace ${nfts[
                  systemId
                ].name.toLowerCase()} - ${chains[chainId].name.toLowerCase()}`}
                chain={chainId}
                systemId={systemId}
                css={{
                  p: '0',
                  '&:hover': {
                    color: 'white',
                  },
                }}
              >
                {chains[chainId].name}
              </NavItem>
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu.Root>
  )
}

export default PopChainDropdown
