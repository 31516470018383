import { zeroAddress } from 'viem'
import { mainnet, sepolia } from 'viem/chains'

const ETH_DECIMALS = mainnet.nativeCurrency.decimals

const testnetCurrencies = [
  {
    address: zeroAddress,
    symbol: 'ETH',
    decimals: sepolia.nativeCurrency.decimals,
    chain: {
      id: sepolia.id,
      name: sepolia.name,
    },
    coinGeckoId: 'ethereum',
    weth: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9'
  },
  {
    address: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
    symbol: 'WETH',
    decimals: sepolia.nativeCurrency.decimals,
    chain: {
      id: sepolia.id,
      name: sepolia.name,
    },
    coinGeckoId: 'weth',
  },
  {
    address: zeroAddress,
    symbol: 'ETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70800,
      name: 'Barret',
    },
    coinGeckoId: 'ethereum',
    weth: '0xBfB86801053600dd3C7FCBa6d5E85017a64cE728'
  },
  {
    address: '0xBfB86801053600dd3C7FCBa6d5E85017a64cE728',
    symbol: 'WETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70800,
      name: 'Barret',
    },
    coinGeckoId: 'weth',
  },
  {
    address: zeroAddress,
    symbol: 'ETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70805,
      name: 'Cloud',
    },
    coinGeckoId: 'ethereum',
    weth: '0x80F30227bd7A2b7128e7C598e3f892ED5891f3b9'
  },
  {
    address: '0x80F30227bd7A2b7128e7C598e3f892ED5891f3b9',
    symbol: 'WETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70805,
      name: 'Cloud',
    },
    coinGeckoId: 'weth',
  },
]

const mainnetCurrencies = [
  {
    address: zeroAddress,
    symbol: 'ETH',
    decimals: mainnet.nativeCurrency.decimals,
    chain: {
      id: mainnet.id,
      name: mainnet.name,
    },
    coinGeckoId: 'ethereum',
    weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
  },
  {
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    symbol: 'WETH',
    decimals: mainnet.nativeCurrency.decimals,
    chain: {
      id: mainnet.id,
      name: mainnet.name,
    },
    coinGeckoId: 'weth',
  },
  {
    address: zeroAddress,
    symbol: 'ETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70700,
      name: 'Apex',
    },
    coinGeckoId: 'ethereum',
    weth: '0x77684A04145a5924eFCE0D92A7c4a2A2E8C359de'
  },
  {
    address: '0x77684A04145a5924eFCE0D92A7c4a2A2E8C359de',
    symbol: 'WETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70700,
      name: 'Apex',
    },
    coinGeckoId: 'weth',
  },
  {
    address: zeroAddress,
    symbol: 'ETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70701,
      name: 'Boss',
    },
    coinGeckoId: 'ethereum',
    weth: '0x48A9B22b80F566E88f0f1DcC90Ea15A8A3bAE8a4'
  },
  {
    address: '0x48A9B22b80F566E88f0f1DcC90Ea15A8A3bAE8a4',
    symbol: 'WETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70701,
      name: 'Boss',
    },
    coinGeckoId: 'weth',
  },
  {
    address: '0x993232978869f1772Fb5D282Fdbcb0f6C5ef744D',
    symbol: 'WETH',
    decimals: ETH_DECIMALS,
    chain: {
      id: 70701,
      name: 'Boss',
    },
    coinGeckoId: 'weth',
  },
]

export const currencies = process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT === 'production' ? mainnetCurrencies : testnetCurrencies
