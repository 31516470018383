import { useAccount, useSwitchChain, useWriteContract } from 'wagmi'

import { Anchor, Button, Flex, Input, Text } from 'components/primitives'
import { Dialog } from 'components/primitives/Dialog'
import { ToastContext } from 'context/ToastContextProvider'
import { SyntheticEvent, useContext, useState } from 'react'
import { formatUnits, parseEther } from 'viem'
import wethAbi from 'abi/weth.abi.json'

interface Currency {
  address: string
  symbol: string
  balance: string | number | bigint
  decimals: number
  chain: {
    id: number
    name: string
  }
  coinGeckoId: string
  weth?: `0x${string}`
}

interface Params {
  currency: Currency
  mode: "wrap" | "unwrap"
}

const Unwrap = ({ currency, mode }: Params): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const balance =
    (typeof currency.balance === 'number'
      ? String(currency.balance)
      : String(
          +formatUnits(BigInt(currency.balance), currency.decimals || 18)
        )) ?? '0'
  const [amount, setAmount] = useState<string>(balance)
  const { writeContract, isPending: isLoading } = useWriteContract();

  const { chain } = useAccount()
  const { switchChainAsync } = useSwitchChain()
  const { addToast } = useContext(ToastContext)

  const onUnwrap = async (e: SyntheticEvent) => {
    e.preventDefault()

    try {
      const etherAmount = parseEther(String(amount))
      if (etherAmount <= BigInt(0)) {
        addToast?.({
          status: 'error',
          title: 'Invalid amount',
          description: 'Please enter a valid amount.',
        })
        return
      }

      const etherBalanceAmount = parseEther(balance)
      if (etherAmount > BigInt(etherBalanceAmount)) {
        addToast?.({
          status: 'error',
          title: 'Insufficient balance',
          description: `You do not have enough balance to ${mode}.`,
        })
        return
      }

      if (chain?.id !== currency.chain.id) {
          await switchChainAsync({chainId: currency.chain.id})
      }

      if (mode === "unwrap") {
        writeContract({ 
          address: currency.address as `0x${string}`,
          abi: wethAbi,
          functionName: 'withdraw',
          args: [etherAmount]
        }) 
      } else {
        writeContract({
          address: currency.weth as `0x${string}`,
          abi: wethAbi,
          functionName: 'deposit',
          args: [],
          value: etherAmount
        })
      }

      addToast?.({
        status: 'success',
        title: 'Transaction submitted',
        description: mode === 'unwrap' ? `Transaction to unwrap WETH has been submitted.` :  `Transaction to wrap WETH has been submitted.`,
      })

      setDialogOpen(false)
    } catch (e) {
      addToast?.({
        status: 'error',
        title: 'User canceled transaction',
        description: 'You have canceled the transaction.',
      })
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(open) => setDialogOpen(open)}
      trigger={
        <Anchor color="primary" weight="normal" css={{ fontSize: 12, textTransform: 'capitalize' }}>
          {mode}
        </Anchor>
      }
      overlayProps={{ style: { opacity: 0.7 } }}
    >
      <Flex direction="column" css={{ p: 24, gap: '$4' }}>
        <Text style="h4"><span style={{ textTransform: 'capitalize' }}>{mode}</span> WETH</Text>
        <Flex
          direction="row"
          css={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '$3',
            alignSelf: 'stretch',
          }}
        >
          <Input
            type="number"
            containerCss={{ flex: 1 }}
            defaultValue={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Button disabled={isLoading} onClick={onUnwrap}>
            <span style={{ textTransform: 'capitalize'}}>{mode}</span><span> WETH</span>
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  )
}

export default Unwrap