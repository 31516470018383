import {
  Root as ToggleGroupRoot,
  Item as ToggleGroupItem,
} from '@radix-ui/react-toggle-group'
import { styled } from 'stitches.config'

const StyledToggleGroupRoot = styled(ToggleGroupRoot, {
  borderRadius: 6,
  overflow: 'hidden',
  display: 'flex',
})

const StyledToggleGroupItem = styled(ToggleGroupItem, {
  background: '$gray3',
  color: '$gray12',
  p: '$3',
  '&[data-state=on]': {
    '& > div:nth-of-type(1)': {
      backgroundColor: '$black400',
      transition: 'background-color 300ms ease',
    },
  },
})

export {
  StyledToggleGroupRoot as ToggleGroup,
  StyledToggleGroupItem as ToggleGroupItem,
  StyledToggleGroupRoot as ToggleGroupRoot,
}
