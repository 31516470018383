import { useRef, useState, useEffect } from 'react'
import { Box, Button, Flex } from '../primitives'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'components/custom/Link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import ThemeSwitcher from './ThemeSwitcher'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import CartButton from './CartButton'
import { AccountSidebar } from 'components/navbar/AccountSidebar'
import NftNavItems from './NftNavItems'
import { useAuthWallets } from 'hooks/auth/useAuthWallets'

export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77

const Navbar = () => {
  const router = useRouter()
  const { ownershipWallet } = useAuthWallets()
  const isConnected = Boolean(ownershipWallet?.address)

  const isMobile = useMediaQuery({ query: '(max-width: 960px' })
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()
  const isHomepage = router.pathname === '/'

  let searchRef = useRef<HTMLInputElement>(null)

  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  if (!isMounted) {
    return null
  }

  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        zIndex: 999,
        background: '$neutralBg',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box css={{ flex: 1 }}>
        <Flex align="center">
          <Link href="/" analyticsId="click marketplace home">
            <Box css={{ cursor: 'pointer' }}>
              <Image
                src="/pop-marketplace-logo.svg"
                width={200} // Make this  bigger or smaller, the height will adjust automatically when it renders
                height={35}
                alt="Proof of Play Marketplace"
              />
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex align="center" css={{ gap: '$3' }}>
        {/* <CartButton /> */}
        <HamburgerMenu key={`${router.asPath}-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,
        px: '$5',
        '@xl': {
          px: '$6',
        },
        width: '100%',
        // maxWidth: 1920,
        mx: 'auto',
        zIndex: 999,
        background: '$neutralBg',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box
        css={{
          display: 'flex',
          padding: isHomepage ? '0 115px 0 115px' : '20px 0 20px 0',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex align="center">
          <Link href="/" analyticsId="click marketplace home">
            <Box css={{ cursor: 'pointer' }}>
              <Image
                src="/pop-marketplace-logo.svg"
                width={150}
                height={35}
                alt="Proof of Play Marketplace"
              />
            </Box>
          </Link>
          <NftNavItems />
        </Flex>
      </Box>
      <Flex
        css={{
          gap: '$3',
          flex: 'unset',
        }}
        justify="end"
        align="center"
      >
        <Flex css={{ gap: '$5', mr: 12 }}>
          {isConnected && (
            <Link
              href={`/portfolio/${
                ownershipWallet?.address || ''
              }?chain=${routePrefix}`}
            >
              <Box css={{ mr: '$2' }}>
                <Button
                  analyticsId="click marketplace header sell"
                  color="primary"
                  css={{
                    h: 40,
                    w: 88,
                    justifyContent: 'center',
                  }}
                  size="xs"
                >
                  Sell
                </Button>
              </Box>
            </Link>
          )}
        </Flex>

        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
        {/* <CartButton /> */}
      </Flex>
    </Flex>
  )
}

export default Navbar
