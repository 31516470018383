import { Text } from 'components/primitives'
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useContext,
} from 'react'
import { CollectionContext } from 'context/CollectionContextProvider'

import { withClickAnalytics } from 'components/wrappers/withClickAnalytics'

type NavItemProps = {
  children: ReactNode
  chain?: string
  systemId?: string
}

const NavItem = withClickAnalytics(
  forwardRef<
    HTMLParagraphElement,
    ComponentPropsWithoutRef<typeof Text> & NavItemProps
  >(({ children, chain = '', systemId = '', ...props }, forwardedRef) => {
    const { selectedCollection } = useContext(CollectionContext)
    const isSelected = selectedCollection?.name === children

    return (
      <Text
        {...props}
        ref={forwardedRef}
        css={{
          color: '$gray12',
          cursor: 'pointer',
          fontWeight: 700,
          p: '$1 $4',
          border: isSelected
            ? '1px solid $neutralBgContent'
            : '1px solid transparent',
          borderRadius: isSelected ? 4 : 0,
          backgroundColor: isSelected ? 'rgba(255,255,255,0.04)' : 'none',
          '&:hover': {
            color: '$gray11',
          },
          ...props.css,
        }}
        as="p"
        style="subtitle1"
      >
        {children}
      </Text>
    )
  })
)

export default NavItem
