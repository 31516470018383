import { usePrivy } from "@privy-io/react-auth";

export const useLogout = () => {
    // Disable logout when Privy is not ready or the user is not authenticated
    const { ready, authenticated, logout: privyLogout } = usePrivy();
    const isDisabled = !ready || (ready && !authenticated);

    const logout = async () => await privyLogout().catch(console.error);

    return {
        isDisabled,
        logout,
    };
};
