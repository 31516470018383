import { FC, useContext } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { Box, Text } from '../primitives'
import {
  ToggleGroup,
  ToggleGroupItem,
} from 'components/custom/toggles/ToggleGroup'
import { TooltipArrow } from 'components/primitives/Tooltip'
import { PriceDisplayContext } from 'context/PriceDisplayContextProvider'
import { useMounted } from 'hooks'
import { defaultPriceDisplays } from 'utils/priceDisplay'

interface PriceDisplayToggleProps {
  height?: string
}

const PriceDisplayToggle: FC<PriceDisplayToggleProps> = ({ height = '48' }) => {
  const { priceDisplay, setPriceDisplay } = useContext(PriceDisplayContext)
  const isMounted = useMounted()

  if (!isMounted) {
    return null
  }

  return (
    <Box
      css={{
        borderRadius: 6,
      }}
    >
      <ToggleGroup type="single" value={priceDisplay}>
        {defaultPriceDisplays.map((priceDisplayOption) => (
          <TooltipPrimitive.Root
            delayDuration={0}
            key={`${priceDisplayOption.value}`}
          >
            <TooltipPrimitive.Trigger>
              <ToggleGroupItem
                asChild
                value={priceDisplayOption.value}
                onClick={() => {
                  setPriceDisplay(priceDisplayOption.value)
                }}
                css={{ p: 4 }}
              >
                <Box
                  css={{
                    width: 56,
                    height,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    css={{
                      borderRadius: 4,
                      width: 52,
                      height: `calc(${height}px - 8px)`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={priceDisplayOption.darkIconUrl}
                      style={{ height: 20 }}
                    />
                  </Box>
                </Box>
              </ToggleGroupItem>
            </TooltipPrimitive.Trigger>
            <TooltipPrimitive.Content
              sideOffset={4}
              side="top"
              align="center"
              style={{
                zIndex: 100,
                filter: 'drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.3))',
              }}
            >
              <TooltipArrow />
              <Box
                css={{
                  zIndex: 9999,
                  $$shadowColor: '$colors$panelShadow',
                  boxShadow: '0px 1px 5px rgba(0,0,0,0.2)',
                  borderRadius: 8,
                  overflow: 'hidden',
                }}
              >
                <Box
                  css={{
                    background: '$neutralBgSubtle',
                    p: '$2',
                  }}
                >
                  <Text style="body3" as="p">
                    {priceDisplayOption.tooltipText}
                  </Text>
                </Box>
              </Box>
            </TooltipPrimitive.Content>
          </TooltipPrimitive.Root>
        ))}
      </ToggleGroup>
    </Box>
  )
}

export default PriceDisplayToggle
